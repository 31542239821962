import Vue from 'vue'

import UiTable from '~/components/ui/ui-table/index.vue'
import UiDatePicker from '~/components/ui/date-picker'
import UiList from '~/components/ui/ui-list/index.vue'
import UiSelect from '~/components/ui/ui-select/index.vue'
import UiTags from '~/components/ui/ui-tags/index.vue'
import UiInputNumber from '~/components/ui/ui-number-input/index.vue'
import UiRange from '~/components/ui/ui-range/index.vue'

const components = { UiTable, UiDatePicker, UiSelect, UiList, UiTags, UiInputNumber, UiRange }
Object
  .entries(components)
  .forEach(([name, component]) => {
    Vue.component(name, component)
  })
