import { AxiosError } from 'axios'
import { Context } from '@nuxt/types'
import { AddressSuggest, DadataPlugin, orgSuggest } from './interface'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

export default function getPluginApi (): DadataPlugin {
  const ctx: Context = this

  const api: DadataPlugin = {
    async fetch (address: string): Promise<AddressSuggest[]> {
      try {
        return (await $axios.get(
          `${ctx.$config.SITE_API}/dadata/v1/address?query=${address}`
        ))?.data || [] as AddressSuggest[] // получаем похожие адреса
      } catch (error) {
        console.error(error)
        throw new FormError(error as AxiosError<FormError>)
      }
    },

    async party (inn: string): Promise<orgSuggest[]> {
      try {
        return (await $axios.get(
          `${ctx.$config.SITE_API}/dadata/v1/party?query=${inn}`
        ))?.data || [] as orgSuggest[] // получаем похожие адреса
      } catch (error) {
        console.error(error)
        throw new FormError(error as AxiosError<FormError>)
      }
    }
  }

  return api
}
