/**
 * * Получить окончание слова в зависимости от числа
 * @param n - число
 * @param titles - массив названий. Например: ['яблоко', 'яблока', 'яблок']
 */

export function wordRussianEnding (n: number, titles: Array<string>): string {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[(n % 100 > 4 && n % 100 < 20) ? 2 : cases[Math.min(n % 10, 5)]]
}
