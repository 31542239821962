import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, PageParams, ResponseData, ValidatorParams } from '../../interfaces'
import { User, FiltersParams, UserPassword, TokenParams } from './interfaces'
import { $axios } from '~/utils/api'
import { dateOneDayLater } from '~/utils/date'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'users',
  stateFactory: true,
  namespaced: true
})

export default class UsersModule extends VuexModule {
  /**
   * Обьект с паролями пользователя
   */
  userPasswordValue: UserPassword = {
    password: '',
    verifyPassword: ''
  }

  /**
   * * Массив всех user исходя из запроса
   */
  users: ResponseData<User> = defaultData

  filtersValues: FiltersParams = {
    id: undefined,
    email: undefined,
    name: undefined,
    login: undefined,
    role: undefined,
    phone: undefined,
    inn: undefined,
    org_name: undefined,
    createdAtFrom: undefined,
    createdAtTo: undefined,
    fio: undefined,
    city: undefined,
    competencyIds: undefined
  }

  /**
   * * Текущая user
   */
  user: User = {
    login: '',
    email: '',
    phone: '',
    status: 1,
    role: '',
    userTypeId: 1,
    name: '',
    created: 0
  }

  // ? ______________ getters ______________
  /**
   * * Получить обьект паролей пользователя
   */
  get userPassword (): UserPassword {
    return this.userPasswordValue
  }

  /**
   * * Получить массив users и пагинацией
   */
  get userList (): ResponseData<User> {
    return this.users
  }

  /**
   * * Получить filters
   */
  get filters (): FiltersParams {
    return this.filtersValues
  }

  /**
   * * Получить user из массива users
   */
  get userById () {
    const users = this.users
    return function (id: number): User | undefined {
      return users.data.find(user => user.id === id)
    }
  }

  get validators (): ValidatorParams {
    return {
      login: [{ required: true, message: 'Введите логин', trigger: 'blur' },
        {
          pattern: /^.+$/,
          message: 'Введите корректный логин',
          trigger: ['blur']
        }],
      phone: [{ required: true, message: 'Введите номер телефона', trigger: 'blur' },
        {
          pattern: /^(\+7|7|8){1}[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}$/,
          message: 'Введите корректный номер телефона',
          trigger: ['blur']
        }],
      name: [
        { required: true, message: 'Введите ФИО', trigger: 'blur' },
        {
          pattern: validatorsPattern.fioInput,
          message: 'Введите корректное ФИО',
          trigger: ['blur']
        }
      ],
      role: [{ required: true, message: 'Выберите роль', trigger: 'blur' }],
      email: [{ required: true, message: 'Введите email', trigger: 'blur' },
        { type: 'email', message: 'Введите корректный email', trigger: ['blur'] }
      ],
      orgName: [{ required: true, message: 'Введите организацию', trigger: 'blur' }],
      inn: [
        { required: true, message: 'Введите ИНН', trigger: 'blur' },
        {
          pattern: /^(\d{12}|\d{10})$/,
          message: 'Введите корректный ИНН',
          trigger: ['blur']
        }
      ],
      ogrn: [
        { required: false, message: 'Введите ОГРН', trigger: 'blur' },
        {
          pattern: /^\d{13}$/,
          message: 'Введите корректный ОГРН',
          trigger: ['blur']
        }
      ],
      kpp: [
        {
          pattern: /^\d{9}$/,
          message: 'Введите корректный КПП',
          trigger: ['blur']
        }
      ],
      rs: [
        {
          pattern: /^\d{20}$/,
          message: 'Введите корректный расчетный счет',
          trigger: ['blur']
        }
      ],
      ks: [
        {
          pattern: /^\d{20}$/,
          message: 'Введите корректный корреспондентский счет',
          trigger: ['blur']
        }
      ],
      bic: [
        {
          pattern: /^\d{9}$/,
          message: 'Введите корректный БИК',
          trigger: ['blur']
        }
      ],
      password: [
        { required: true, message: 'Введите пароль', trigger: 'blur' },
        { min: 6, message: 'Минимальная длина 6 символов', trigger: 'blur' }
      ],
      verifyPassword: [
        { required: true, message: 'Повторите пароль', trigger: 'blur' },
        { type: 'enum', enum: [this.currentUser.id ? `${this.userPassword.password}` : `${this.currentUser.password}`], message: 'Пароли не соответствуют', trigger: 'blur' }
      ]
    }
  }

  /**
   * * Получить текущую user для измения или создания user
   */
  get currentUser () {
    return this.user
  }

  // ? ______________ setters ______________
  /**
   * * Установить обьект паролей пользователя
   * @param users массив Users
   */
  @Mutation
  setUserPassword (password: UserPassword) {
    this.userPasswordValue = password
  }

  /**
   * * Установить массив Users
   * @param users массив Users
   */
  @Mutation
  setUsersList (users: ResponseData<User>) {
    this.users = users
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: FiltersParams) {
    this.filtersValues = filters
  }

  /**
   * * Установить CurrentUser для измения или создания user
   * @param user текущая User, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentUser (user: User) {
    this.user = user
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentUser () {
    this.user = {
      login: '',
      email: '',
      phone: '',
      status: 1,
      role: '',
      userTypeId: 1,
      name: '',
      created: 0
    }
  }

  /**
   * * Обнулить фильтры
   */
  @Mutation
  resetFilters () {
    this.filtersValues = {
      id: undefined,
      email: undefined,
      name: undefined,
      login: undefined,
      role: undefined,
      active: undefined,
      fio: undefined,
      phone: undefined,
      inn: undefined,
      org_name: undefined,
      createdAtFrom: undefined,
      createdAtTo: undefined,
      appointment: undefined,
      manager_fio: undefined,
      edo: undefined,
      edoid: undefined,
      userTypeId: undefined,
      hasRelatedAccounts: undefined,
      lastOrderDateFrom: undefined,
      lastOrderDateTo: undefined,
      lastActiveFrom: undefined,
      lastActiveTo: undefined,
      ordersCountFrom: undefined,
      ordersCountTo: undefined,
      ordersSumFrom: undefined,
      ordersSumTo: undefined
    }
  }

  /**
   * * Обнулить список пользователей
   */
  @Mutation
  resetUsers () {
    this.users = defaultData
  }

  @Mutation
  resetUserPassword () {
    this.userPasswordValue = {
      password: '',
      verifyPassword: ''
    }
  }
  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Users по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true
  })
  async getUsers (pageParams?: PageParams) {
    try {
      const { data } = await $axios.get('/users', {
        params: {
          ...pageParams,
          ...this.filtersValues,
          competencyIds: this.filtersValues.competencyIds ? this.filtersValues.competencyIds.join(',') : undefined,
          createdAtFrom: this.filtersValues.createdAtFrom ? +this.filtersValues.createdAtFrom : undefined,
          createdAtTo: this.filtersValues.createdAtTo ? dateOneDayLater(+this.filtersValues.createdAtTo) : undefined
        }
      })
      const response: ResponseData<User> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить user по id
   * @param id id user которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentUser'
  })
  async getUser (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/users/${id}`)
      const response: User = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

    /**
   * * Получить user по id
   * @param id id user которую мы хотим получить
   */
    @Action({
      rawError: true
    })
  async getUserWithoutCommit (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/users/${id}`)
      const response: User = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить user
   */
  @Action({
    rawError: true
  })
    async editUser (siteDomain: string) {
      try {
        const { id, ...user } = this.currentUser
        const userActivity = { active: user.active }
        await $axios.put(`${siteDomain}/api/users/v1/admin/users/${id}/activity`, userActivity)
        const { data: { data } } = await $axios.put(`/users/${id}`, user)
        const response: User = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

  /**
   * * Изменить user
   */
  @Action({
    rawError: true
  })
  async changePassword () {
    try {
      const { data: { data } } = await $axios.put(`/users/${this.currentUser.id}/change-password`, this.userPassword)
      const response: User = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать User
   */
  @Action({
    rawError: true
  })
  async createUser () {
    try {
      const { data: { data } } = await $axios.post('/users', this.currentUser)
      const response: User = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить дату до редактирования
   */
  @Action({
    rawError: true
  })
  async recoverData () {
    try {
      const { data: { data } } = await $axios.get(`/users/${this.currentUser.id}/get-profile`, { params: { role: this.currentUser.role } })
      const response: User = data
      this.setCurrentUser({ ...this.currentUser, ...data, name: data.fio })
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить user по id
   * @param id  id user которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeUser (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/users/${id}`)
      const response: User = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить токены пользователя
   * @param id  id user
   * @returns токены
   */
  @Action({
    rawError: true
  })
  async getUserTokens (id: number) {
    try {
      const { data } = await $axios.post(`/users/${id}/get-token`)
      const response: TokenParams = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Выгрузить в excel
   */
  @Action({
    rawError: true
  })
  async exportExcel (pageParams: PageParams | null) {
    try {
      const { data } = await $axios.get('/users/excel', {
        params: {
          ...pageParams,
          ...this.filtersValues,
          createdAtFrom: this.filtersValues.createdAtFrom ? +this.filtersValues.createdAtFrom : undefined,
          createdAtTo: this.filtersValues.createdAtTo ? dateOneDayLater(+this.filtersValues.createdAtTo) : undefined
        },
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
