import { Module, VuexModule, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError } from '../../interfaces'
import { GeneratorParams, GeneratorOutput } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'generate',
  stateFactory: true,
  namespaced: true
})
export default class GenerateModule extends VuexModule {
  // ? ______________________________________actions______________________________________

  /**
   * * Генерация seo pages для products
   */
  @Action({
    rawError: true
  })
  async generateProducts (productId?: number) {
    try {
      const {
        data: { data }
      } = await $axios.post(
        '/seo/generator/generate/products' + productId ? `/${productId}` : ''
      )
      const response: GeneratorOutput = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Генерация seo pages для filters
   */
  @Action({
    rawError: true
  })
  async generateFilters (inputParams: GeneratorParams | null) {
    try {
      const { data } = await $axios.post(
        '/seo/generator/generate/filters' +
        (inputParams?.category ? `/${inputParams.category}` : ''),
        { depth: inputParams?.depth }
      )
      const response: GeneratorOutput = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Генерация seo превью для полей
   */
  @Action({
    rawError: true
  })
  async generateSeoPreview (inputParams: GeneratorParams | null) {
    try {
      const { data } = await $axios.post(
        '/seo/generator/generate/test',
        inputParams
      )
      const response: GeneratorOutput = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
