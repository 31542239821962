import ActionPlugin from './action/plugin'
import ActionModule from './action/store'
import CitiesPlugin from './cities'
import CitiesModule from './cities/store'
import StagesPlugin from './stages'
import StagesModule from './stages/store'
import TeamProfileFieldsPlugin from './team-profile-fields'
import TeamProfileFieldsModule from './team-profile-fields/store'
import CompetencyPlugin from './competency'
import CompetencyModule from './competency/store'
import AgeGroupPlugin from './age-group'
import AgeGroupModule from './age-group/store'
import AgeTypePlugin from './age-type'
import AgeTypeModule from './age-type/store'
import ChampionshipsPlugin from './championships'
import ChampionshipsModule from './championships/store'
import PermissionPlugin from './permission'
import PermissionModule from './permission/store'

export { ActionModule as ChampionshipActionModule }
export { StagesModule as ChampionshipStagesModule }
export { CitiesModule as ChampionshipCitiesModule }
export { TeamProfileFieldsModule as ChampionshipTeamProfileFieldsModule }
export { CompetencyModule as ChampionshipCompetencyModule }
export { AgeGroupModule as ChampionshipAgeGroupModule }
export { AgeTypeModule as ChampionshipAgeTypeModule }
export { ChampionshipsModule as ChampionshipChampionshipsModule }
export { PermissionModule as ChampionshipPermissionModule }

export class ChampionshipPlugin {
  action: ActionPlugin
  stages: StagesPlugin
  cities: CitiesPlugin
  teamProfileFields: TeamProfileFieldsPlugin
  competency: CompetencyPlugin
  ageGroup: AgeGroupPlugin
  ageType: AgeTypePlugin
  championships: ChampionshipsPlugin
  permission: PermissionPlugin

  constructor (
    actionModule: ActionModule,
    stagesModule: StagesModule,
    citiesModule: CitiesModule,
    teamProfileFieldsModule: TeamProfileFieldsModule,
    competencyModule: CompetencyModule,
    ageGroupModule: AgeGroupModule,
    ageTypeModule: AgeTypeModule,
    permissionModule: PermissionModule,
    championshipsModule: ChampionshipsModule
  ) {
    this.action = new ActionPlugin(actionModule)
    this.stages = new StagesPlugin(stagesModule)
    this.cities = new CitiesPlugin(citiesModule)
    this.teamProfileFields = new TeamProfileFieldsPlugin(teamProfileFieldsModule)
    this.competency = new CompetencyPlugin(competencyModule)
    this.ageGroup = new AgeGroupPlugin(ageGroupModule)
    this.ageType = new AgeTypePlugin(ageTypeModule)
    this.permission = new PermissionPlugin(permissionModule)
    this.championships = new ChampionshipsPlugin(championshipsModule)
  }
}

export default function getChampionshipApi (
  actionModule: ActionModule,
  stagesModule: StagesModule,
  citiesModule: CitiesModule,
  teamProfileFieldsModule: TeamProfileFieldsModule,
  competencyModule: CompetencyModule,
  ageGroupModule: AgeGroupModule,
  ageTypeModule: AgeTypeModule,
  permissionModule: PermissionModule,
  championshipsModule: ChampionshipsModule
) {
  return new ChampionshipPlugin(
    actionModule,
    stagesModule,
    citiesModule,
    teamProfileFieldsModule,
    competencyModule,
    ageGroupModule,
    ageTypeModule,
    permissionModule,
    championshipsModule
  )
}
